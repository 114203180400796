<template>
    <div class="row mt-2">
		<label class="col-4 col-form-label" for="horse_race">{{ $t('horse.race') }}</label>	
        <div class="col-8">
            <b-input-group>
                    <select
                    id="horse_race"
                    class="custom-select"
                    ref="select_race"
                    :placeholder="$t('horse.race_cheval')"
                    v-on:change="checkSelect"
                    v-model="selected_race"
                    @change="$emit('change', $event.target.value)"
                    :disabled="!isEditable"
                >
                    <option
                        v-for="horse_race in horse_races"
                        :value="horse_race.race_code"
                        :key="horse_race.race_code"
                        >{{ horse_race.race_label }}
                    </option>
                </select>
            </b-input-group>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Config from "@/mixins/Config.js";
    import HorseMixin from "@/mixins/Horse.js";

    export default {
        mixins: [Config, HorseMixin],
        props: [
            'horse_id',
            'horse_race_code',
            'editable'
        ],
        data (){
            return {
                horse_races: [],
                edit_field_race: false,
                selected_race: "",
                local_horse_race_code: ""
            }
        },
        computed: {
            isEditable () {
                if(this.editable !== undefined){
                    return this.editable
                }
                return false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.horse_races = await this.getRaces()
                this.local_horse_race_code = this.horse_race_code
                this.selected_race = this.local_horse_race_code
            },
            checkSelect() {
                if(this.selected_race != this.local_horse_race_code ) {
                    this.edit_field_race = true
                    this.local_horse_race_code = this.selected_race
                }
            },
            openSelect() {
                this.$refs.select_race.click()
            },
            saveSelect() {
                this.edit_field_race = false
                this.updateHorserace(this.horse_id, this.selected_race)
            }
        },
        watch:{
            horse_race_code(){
                this.selected_race = this.horse_race_code
                this.checkSelect()
            }
        }
    }
</script>
